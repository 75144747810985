import React from "react";
import { Link } from "gatsby";

import { Layout } from "components/layout_v2/index";
import { NewsWrapperType, PageContextWrapperType } from "models/graphql";

export const NewsArticle = ({ pageContext }: PageContextWrapperType<NewsWrapperType>) => {
  const { payload, contact, pagination } = pageContext;
  const { previous, next } = pagination;

  return (
    <Layout pageContext={pageContext} contact={contact}>
      <section className="blog-details-area ptb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12 offset-lg-2">
              <div className="blog-details">
                <div className="article-img">
                  <img src={payload.image.url} alt="blog-details" />
                </div>

                <div className="article-content">
                  {/* <ul className="category">
                    <li>
                      <Link to="#">Category 1</Link>
                    </li>

                    <li>
                      <Link to="#">Category 2</Link>
                    </li>

                    <li>
                      <Link to="#">Category 3</Link>
                    </li>
                  </ul> */}

                  <h3>{payload.title.text}</h3>

                  <div dangerouslySetInnerHTML={{ __html: payload.body.html }} />
                </div>
              </div>

              <div className="post-controls-buttons">
                <div className="controls-left">
                  {!!previous && (
                    <Link to={`/news/${previous}`}>
                      <i className="icofont-double-left"></i> Prev Post
                    </Link>
                  )}
                </div>

                <div className="controls-right">
                  {!!next && (
                    <Link to={`/news/${next}`}>
                      Next Post <i className="icofont-double-right"></i>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NewsArticle;
